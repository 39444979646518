import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions/ProjectsActions";
import { getContainerLogsWebSocketUrl } from "utility/websocketUrls";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';


const ContainerLogs = ({containerId}) => {
    const dispatch = useDispatch();
    const { previewLogs } = useSelector((state) => state.projectsReducer)
    const { user } = useSelector((state) => state.authReducer)
    const logsContainerRef = useRef(null);
    const [scrollDirection, setScrollDirection] = useState("down");


    useEffect(() => {
        const socketUrl = getContainerLogsWebSocketUrl(containerId, user.uid)
        if (!socketUrl)
          return
        const socket = new WebSocket(socketUrl);
        socket.onopen = () => {
          dispatch(actions.reappendContainerLogs())
        }
        socket.onmessage = (event) => {
          dispatch(actions.appendContainerLogs(event.data))
        };
        return () => {
          // Close the WebSocket connection when the component is unmounted
          if (socket.readyState === WebSocket.OPEN) {
            socket.close();
          }
        };
      }, [dispatch,containerId, user.uid]);

      const handleScrollButtonClick = () => {
        if (logsContainerRef.current) {
          if (scrollDirection === "down") {
            logsContainerRef.current.scrollTo({
              top: logsContainerRef.current.scrollHeight,
              behavior: "smooth",
            });
            setScrollDirection("up");
          } else {
            logsContainerRef.current.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            setScrollDirection("down");
          }
        }
      };
    
      // Handle scroll event to dynamically change button text
      const handleScroll = () => {
        if (logsContainerRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = logsContainerRef.current;
          if (scrollTop + clientHeight >= scrollHeight) {
            setScrollDirection("up");
          } else if (scrollTop === 0) {
            setScrollDirection("down");
          }
        }
      };



      return (
        <div>
          <div
            className="container_logs_text"
            ref={logsContainerRef}
            onScroll={handleScroll}
            style={{ maxHeight: "90vh", overflowY: "auto" }} 
          >
            <div>{previewLogs}</div>
            {!previewLogs && containerId && "Loading..."}
          </div>
          <button onClick={handleScrollButtonClick} className="scroll-button">
          {scrollDirection === "down" ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
          </button>
        </div>
      );
    };
    
    export default ContainerLogs;
